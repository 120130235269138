<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-white">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      สงวนลิขสิทธิ์ พ.ศ.2565 ສະບາຍດີ ล็อตเตอรี่ ออนไลน์
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block text-white">นโยบายความเป็นส่วนตัว | นโยบายคุกกี้</span>
    <ThisFooter />
  </p>

</template>

<script>
import ThisFooter from './ThisFooter.vue'
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    ThisFooter,
    // BLink,
  },
}
</script>
