export default [
  {
    title: 'หน้าแรก',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'ซื้อหวย',
    route: 'buy-lottery',
    icon: 'ShoppingBagIcon',
    meta: {
      resource: 'user',
      action: 'read',
    },
  },
  {
    title: 'ซื้อหวยเอเย่นต์',
    route: 'agent-buy-lottery',
    icon: 'ShoppingBagIcon',
    meta: {
      resource: 'agent',
      action: 'read',
    },
  },
]
